import template from './dialog.pug';
import hotkeys from 'hotkeys-js';

export default class Dialog {
    constructor(options) {
        this.boundClose = this.close.bind(this);

        let defaultOptions = {
            containerClass: '',
            title: undefined,
            content: undefined,
            buttons: [
                {
                    content: 'OK',
                    class: 'btn-primary',
                    onClick: this.boundClose
                }
            ]
        }

        if (typeof options == 'string' || options instanceof Element) {
            options = { content: options }
        }
        
        options = Object.assign(defaultOptions, options);

        // If content is an Element or NodeList, add it in dynamically
        // rather than rendering it in the template
        if (
            options.content instanceof Element ||
            options.content instanceof NodeList
        ) {
            this.dynamicContent = options.content;
            options.content = undefined;
        }
        
        this.update(options);
    }

    update(options) {
        this.options = options || this.options;

        this.modal = document.createRange().createContextualFragment(
            template(this.options)
        );

        if (this.dynamicContent) {
            this.modal.querySelector('.modal-body')
                .appendChild(this.dynamicContent);
        }

        if (this.options.buttons && this.options.buttons.length > 0) {
            this.modal.querySelector('.modal-footer').childNodes.forEach(node => {
                node.addEventListener('click', () => 
                    this.options.buttons[node.dataset.key].onClick(this)
                );
            });
        }
    }
    
    open() {
        document.body.appendChild(this.modal);
        this.modal = document.body.lastChild;
        
        hotkeys('esc', { keyDown: false }, this.boundClose);
        if (this.options.title) {
            this.modal.querySelector('.close')
            .addEventListener('click', this.boundClose);
        }

        window.getComputedStyle(this.modal).opacity; // force css reflow
        this.modal.classList.add('show');
    }

    close() {
        hotkeys.unbind('esc', this.boundClose);

        this.modal.classList.remove('show');
        
        this.modal.addEventListener('transitionend', () => {
            document.body.contains(this.modal) ? document.body.removeChild(this.modal) : {}
            this.update();  // reinit modal so multiple events don't stack up
        });

        if (this.options.onClosed instanceof Function) {
            this.options.onClosed();
        }
    }
}