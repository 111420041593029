var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    { attrs: { id: "error", "ok-only": "", "hide-header": "" } },
    [
      _c("p", [
        _vm._v(
          "Sorry, an error occured whilst loading the page.  Please try reloading your browser."
        ),
      ]),
      _c("p", [
        _vm._v("If this message persists, please e-mail "),
        _c("a", { attrs: { href: "mailto:sales@bramptonframing.com" } }, [
          _vm._v("sales@bramptonframing.com"),
        ]),
        _vm._v(" and we will be happy to help."),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }