<template lang="pug">
    b-overlay(:show='loading && (board !== null)')
        b-img(
            v-if='board' 
            :src='url' 
            @load='loading = false'
            :fluid='fluid'
        )
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BOverlay, BImg } from 'bootstrap-vue';
import debounce from 'lodash/debounce';

import { Mountboard, Size } from '@/types';

@Component({
    components: {
        BOverlay, BImg
    }
})
export default class MountVisualisation extends Vue {
    @Prop(Object) board !: Mountboard | null;
    @Prop(Object) externalSize !: Size;
    @Prop(Object) apertureSize !: Size;
    @Prop({ type: Boolean, default: true }) fluid !: boolean;

    loading = true;

    url = '#';

    @Watch('board', { deep: true })
    @Watch('externalSize', { deep: true })
    @Watch('apertureSize', { deep: true })
    debouncedUpdateUrl = debounce(this.updateUrl, 250);

    updateUrl(): void {
        let params = new URLSearchParams({
            width: String(this.externalSize.width),
            length: String(this.externalSize.length),
            apWidth: String(this.apertureSize.width),
            apLength: String(this.apertureSize.length),
            code: this.board ? this.board.stock_lookup_sku : '8001'
        });

        this.url = '/api/visualisations/mountboard?' + params.toString();
    }

    @Watch('url')
    onUrlChange(newVal: string, oldVal: string) {
        if (newVal !== oldVal) this.loading = true;
    }
}
</script>