<template lang="pug">
    b-overlay(:show='loading')
        b-table.mb-0(
            :items='discounts'
            :fields='fields'
            responsive 
            small 
            striped
        )
            template(v-slot:cell(quantity)='data') {{ data.value }} {{ type }}{{ data.value > 1 ? 's' : '' }}
            template(v-slot:cell(price)='data') &pound;{{ data.value }}
            template(v-slot:cell(discount)='data') {{ data.value }}%
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BOverlay, BTable, BvTableFieldArray } from 'bootstrap-vue';

import { Discount } from '@/types';

@Component({
    components: {
        BOverlay, BTable
    }
})
export default class DiscountsBreakdown extends Vue {
    @Prop({ type: Boolean, default: false }) loading !: boolean;
    @Prop({ type: Array, required: true }) discounts !: Discount[];
    @Prop({ type: String }) itemType !: string;

    get type(): string {
        return this.itemType || 'Item';
    }

    fields: BvTableFieldArray = [
        { key: 'qty', label: 'Quantity' },
        { key: 'price', label: `Price / ${this.type}` },
        { key: 'discount', label: '% Off' }
    ];
}
</script>