import uniq from 'lodash/uniq';
/**
 * Get the rebate dimensions from artwork size and mount borders
 * @param artworkSize Exact artwork size
 * @param borders Mount borders
 * @returns Size
 */
export function getRebateDimensions(artworkSize, borders = null) {
    let horizontalBorder = 0;
    let verticalBorder = 0;
    if (borders) {
        horizontalBorder = borders.left + borders.right - 6;
        verticalBorder = borders.top + borders.bottom - 6;
    }
    return {
        width: artworkSize.width + horizontalBorder + 3,
        length: artworkSize.length + verticalBorder + 3
    };
}
/**
 * Get the dimensions of the glass (and other boards) from a rebate size
 * @param rebateSize Rebate size of the frame
 * @returns Size
 */
export function getGlassDimensions(rebateSize) {
    return {
        width: rebateSize.width - 3,
        length: rebateSize.length - 3,
    };
}
/**
 * Get the aperture dimensions from the exact artwork size
 * @param artworkSize Exact artwork size
 * @returns Size
 */
export function getApertureDimensions(artworkSize) {
    return {
        width: artworkSize.width - 6,
        length: artworkSize.length - 6
    };
}
/**
 * Get a string describing the border widths
 * @param borders Size of the mount borders
 * @returns string
 */
export function getMountWidthString(borders) {
    if (uniq(Object.values(borders)).length == 1) {
        return borders.top.toString();
    }
    return `${borders.top}x${borders.left}x${borders.right}x${borders.bottom}`;
}
/**
 * Get a string describing the mount for the cart
 * @param mount Mount options
 * @param apertureSize Aperture size
 * @returns string
 */
function getMountCartString(mount, apertureSize) {
    switch (mount.type) {
        case 'single':
            return `Single ${getMountWidthString(mount.borders)}mm mount (Aperture: ${apertureSize.width}x${apertureSize.length}mm)`;
        case 'double':
            return `Double ${getMountWidthString(mount.borders)}mm mount (Aperture: ${apertureSize.width}x${apertureSize.length}mm) with reveal size of ${mount.reveal}mm`;
        default:
            return 'None';
    }
}
/**
 * Get a string describing the mount colours for the cart
 * @param mount Mount options
 * @returns string
 */
function getMountColourCartString(mount) {
    var _a, _b, _c;
    switch (mount.type) {
        case 'single':
            return ((_a = mount.top) === null || _a === void 0 ? void 0 : _a.name) || 'None';
        case 'double':
            return `Top Mount: ${(_b = mount.top) === null || _b === void 0 ? void 0 : _b.name} / Bottom Mount: ${(_c = mount.bottom) === null || _c === void 0 ? void 0 : _c.name}`;
        default:
            return 'None';
    }
}
/**
 * Get the cart item to add to cart from a bespoke frame item
 * @param item Bespoke frame options from the frame designer
 * @param qty Quantity to add
 * @param price Unit price
 * @returns CartItem
 */
export function getBespokeFrameCartItem(item, qty, price, isDeliveryAvailable = true, imageName = null) {
    var _a, _b;
    const rebateSize = getRebateDimensions(item.size, item.mount.type != 'none' ? item.mount.borders : null);
    const glassSize = {
        length: rebateSize.length - 3,
        width: rebateSize.width - 3
    };
    const apertureSize = getApertureDimensions(item.size);
    const attributes = {
        'To Fit': item.size.name ? `${item.size.name} (${item.size.width}x${item.size.length}mm)` : `${item.size.width}x${item.size.length}mm`,
        'Internal Frame Size - Rebate Size': `${rebateSize.width}x${rebateSize.length}mm`,
        'Glass': item.glazing && item.glazing.name ? item.glazing.name : 'None',
        'Backing': item.extras.backing ? `2.3mm Craftboard (${glassSize.width}x${glassSize.length}mm)` : 'None',
        'Fixings': item.extras.fixings ? 'Picture Framing Cord and 2 D-Rings + Screws' : 'None',
        'Delivery Available': isDeliveryAvailable.toString(),
        'Image': getVisualisationUrl(((_a = item.moulding) === null || _a === void 0 ? void 0 : _a.sku) || null, rebateSize, apertureSize, item.mount, item.extras.backing, imageName, 1)
    };
    const mountString = getMountCartString(item.mount, apertureSize);
    if (mountString !== 'None') {
        attributes['Mount'] = mountString;
        attributes['Mount Colour'] = getMountColourCartString(item.mount);
    }
    if (item.extras.undermount) {
        attributes['Undermount'] = '1.4mm White Larson-Juhl Undermount';
    }
    if (item.extras.fastmount) {
        attributes['Fastmount'] = '1.5mm Permanent Sticky Fastmount';
    }
    if (item.extras.spacer) {
        attributes['Spacer'] = '1.5mm Spacer';
    }
    return {
        sku: `BF-${(_b = item.moulding) === null || _b === void 0 ? void 0 : _b.sku}`,
        qty,
        price,
        attributes
    };
}
/**
 * Get the URL to show the visualisation
 * @param sku SKU of the moulding
 * @param rebateDimensions Rebate dimensions
 * @param apertureSize Size of the aperture
 * @param mount Mount options
 * @param backing Has backing or not
 * @param imageFilename File name of the uploaded image
 * @returns URL to the frame visualization
 */
export function getVisualisationUrl(sku, rebateDimensions, apertureSize, mount, backing, imageFilename = null, scale = null) {
    var _a, _b;
    const urlParams = new URLSearchParams();
    if (sku) {
        urlParams.append('sku', sku);
    }
    urlParams.append('width', rebateDimensions.width.toString());
    urlParams.append('length', rebateDimensions.length.toString());
    if (mount.type != 'none' && mount.top) {
        urlParams.append('apWidth', apertureSize.width.toString());
        urlParams.append('apLength', apertureSize.length.toString());
        urlParams.append('mntTop', mount.borders.top.toString());
        urlParams.append('mntLeft', mount.borders.left.toString());
        urlParams.append('topMntCode', (_a = mount.top) === null || _a === void 0 ? void 0 : _a.stock_lookup_sku);
        if (mount.type == 'double' && mount.bottom && mount.reveal) {
            urlParams.append('bottomMntCode', (_b = mount.bottom) === null || _b === void 0 ? void 0 : _b.stock_lookup_sku);
            urlParams.append('revealSize', mount.reveal.toString());
        }
    }
    urlParams.append('backing', backing ? "1" : "0");
    if (scale) {
        urlParams.append('scale', scale.toString());
    }
    else {
        // Only double the scale if the dimensions are < 1000
        urlParams.append('scale', Math.max(rebateDimensions.width, rebateDimensions.length) < 1000 ? "2" : "1");
    }
    if (imageFilename) {
        urlParams.append('uploadedImageName', imageFilename);
    }
    return `/api/visualisations/frame?${urlParams.toString()}`;
}
