var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "mb-2 mb-sm-0", attrs: { sm: "" } },
        [
          _c("dimension-input", {
            attrs: {
              unit: _vm.unit,
              size: _vm.size,
              readonly: _vm.readonly,
              valid: _vm.isWidthValid,
            },
            model: {
              value: _vm.selectedSize.width,
              callback: function ($$v) {
                _vm.$set(_vm.selectedSize, "width", $$v)
              },
              expression: "selectedSize.width",
            },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center mb-2 mb-sm-0", attrs: { sm: "1" } },
        [
          _vm.showX
            ? _c("p", { staticClass: "mb-0 pt-1" }, [_vm._v("x")])
            : _vm._e(),
        ]
      ),
      _c(
        "b-col",
        { attrs: { sm: "" } },
        [
          _c("dimension-input", {
            attrs: {
              unit: _vm.unit,
              size: _vm.size,
              readonly: _vm.readonly,
              valid: _vm.isLengthValid,
            },
            model: {
              value: _vm.selectedSize.length,
              callback: function ($$v) {
                _vm.$set(_vm.selectedSize, "length", $$v)
              },
              expression: "selectedSize.length",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }