var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BAlert } from 'bootstrap-vue';
let ProductDiscontinued = class ProductDiscontinued extends Vue {
    get url() {
        var _a, _b, _c, _d, _e;
        return `/diy-framing/accessories/${(_c = (_b = (_a = this.replacement) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.type) === null || _c === void 0 ? void 0 : _c.slug}/${(_e = (_d = this.replacement) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.slug}`;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], ProductDiscontinued.prototype, "replacement", void 0);
ProductDiscontinued = __decorate([
    Component({
        components: {
            BAlert
        }
    })
], ProductDiscontinued);
export default ProductDiscontinued;
