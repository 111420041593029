var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { BRow, BCol } from 'bootstrap-vue';
import DimensionInput from './DimensionInput.vue';
let UnitSelector = class UnitSelector extends Vue {
    get isWidthValid() {
        if (this.valid !== null)
            return this.valid;
        if (this.selectedSize.width < 0)
            return false;
        return null;
    }
    get isLengthValid() {
        if (this.valid !== null)
            return this.valid;
        if (this.selectedSize.length < 0)
            return false;
        return null;
    }
};
__decorate([
    Prop({ type: String, default: 'md' })
], UnitSelector.prototype, "size", void 0);
__decorate([
    Prop({ type: String, default: 'mm' })
], UnitSelector.prototype, "unit", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UnitSelector.prototype, "readonly", void 0);
__decorate([
    Prop({ type: Boolean, default: null })
], UnitSelector.prototype, "valid", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], UnitSelector.prototype, "showX", void 0);
__decorate([
    ModelSync('setSize', 'input', { type: Object })
], UnitSelector.prototype, "selectedSize", void 0);
UnitSelector = __decorate([
    Component({
        components: {
            BRow, BCol,
            DimensionInput
        }
    })
], UnitSelector);
export default UnitSelector;
