<template lang="pug">
    b-input-group(:size='size')
        b-form-input(
            v-if='unit == "mm"'
            :type='inputType'
            number
            min=0
            step=1
            :disabled='readonly'
            :value='rawDimension'
            @input='onInput'
            :state='valid'
        )
        b-form-input(
            v-if='unit == "cm"'
            :type='inputType'
            number
            min=0
            step=0.1
            :disabled='readonly'
            :value='cmDimension'
            @input='onInput'
            :state='valid'
        )
        b-form-input(
            v-if='unit == "inch"'
            :type='inputType'
            number
            min=0
            step=0.1
            :disabled='readonly'
            :value='inchDimension'
            @input='onInput'
            :state='valid'
        )
        b-input-group-append
            b-input-group-text(v-if='unit == `inch` && nearestFrac')
                small 
                    em (~ {{ wholeInch }} {{ nearestFrac }}")
            b-input-group-text {{ unit }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BFormInput, BInputGroup, BInputGroupText, BInputGroupAppend } from 'bootstrap-vue';

import { Unit, ElementSize } from '@/types';

@Component({
    components: {
        BFormInput, BInputGroup, BInputGroupText, BInputGroupAppend
    }
})
export default class DimensionInput extends Vue {
    @Prop({ type: Number, default: 0 }) value !: number;
    @Prop({ type: String, default: 'mm' }) unit !: Unit;
    @Prop({ type: String, default: 'md' }) size !: ElementSize;
    @Prop({ type: Boolean, default: false }) readonly !: boolean;
    @Prop({ type: Boolean, default: null }) valid !: boolean|null;

    onInput(val: number) {
        switch (this.unit) {
            case 'mm':
                this.rawDimension = val;
                break;
            case 'cm':
                this.rawDimension = val * 10;
                break;
            case 'inch':
                this.rawDimension = val * 25.4;
                break;
        }
    }

    get nearestFrac(): string {
        const decimal = (this.rawDimension / 25.4) % 1;
        const frac = Math.round(decimal * 8) / 8;

        const fractions: Record<number, string> = {
            0.000: '',
            0.125: '⅛',
            0.250: '¼',
            0.375: '⅜',
            0.500: '½',
            0.625: '⅝',
            0.750: '¾',
            0.875: '⅞',
        };
        
        return fractions[frac];
    }

    get rawDimension(): number {
        return this.value;
    }

    set rawDimension(dimension: number) {
        this.$emit('input', Math.ceil(dimension));
    }

    get cmDimension(): number {
        return this.rawDimension / 10;
    }

    get inchDimension(): number {
        return Math.round((this.rawDimension / 25.4) * 10) / 10
    }

    get wholeInch(): number {
        return (this.rawDimension / 25.4) - ((this.rawDimension / 25.4) % 1)
    }

    get inputType(): 'number'|'text' {
        return this.readonly ? 'text' : 'number'
    }
}
</script>