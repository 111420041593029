var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-overlay", { attrs: { show: _vm.loading } }, [
    _c("div", { staticClass: "table-responsive mb-0" }, [
      _c("table", { staticClass: "table table-sm table-striped mb-0" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Quantity")]),
            _c("th", [_vm._v("Price / " + _vm._s(_vm.itemType))]),
            _c("th", [_vm._v("% Off")]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.discounts, function (discount, idx) {
            return _c("tr", { key: idx }, [
              _c("td", [
                _vm._v(
                  _vm._s(discount.qty) +
                    " " +
                    _vm._s(_vm.itemType) +
                    _vm._s(discount.qty > 1 ? "s" : "")
                ),
              ]),
              _c("td", [_vm._v("£" + _vm._s(discount.price))]),
              _c("td", [_vm._v(_vm._s(discount.discount) + "%")]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }