import Dialog from '../dialog.inc';

export default class ConfirmDialog extends Dialog {
    /**
     * @param {object} options
     */
    constructor(options) {
        options.buttons = [
            {
                content: 'No',
                class: 'btn-danger',
                onClick: () => {
                    this.onConfirm(false);
                }
            },
            {
                content: 'Yes',
                class: 'btn-success',
                onClick: () => {
                    this.onConfirm(true);
                }
            }
        ];
        
        super(options);
    }

    onConfirm(result) {
        // Run the result callback
        this.options.callback(result)
            // Close the popup after promise resolved
            .then(this.boundClose);
    }
}