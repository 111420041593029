var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { BFormRadioGroup } from 'bootstrap-vue';
let UnitSelector = class UnitSelector extends Vue {
    constructor() {
        super(...arguments);
        this.units = [
            { text: 'mm', value: 'mm' },
            { text: 'cm', value: 'cm' },
            { text: 'inch', value: 'inch' }
        ];
    }
};
__decorate([
    ModelSync('unit', 'input', { type: String })
], UnitSelector.prototype, "selectedUnit", void 0);
__decorate([
    Prop({ type: String, default: 'mm' })
], UnitSelector.prototype, "unit", void 0);
__decorate([
    Prop({ type: String, default: 'md' })
], UnitSelector.prototype, "size", void 0);
UnitSelector = __decorate([
    Component({
        components: { BFormRadioGroup }
    })
], UnitSelector);
export default UnitSelector;
