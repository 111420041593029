<template lang="pug">
    b-overlay(:show='!isValid' opacity=0.5)
        p
            strong Size: 
            |  {{ externalSize.length }} x {{ externalSize.width }}mm external 
            |  with {{ apertureSize.length }} x {{ apertureSize.width }}mm aperture
            br
            strong Colour:
            |  {{ board ? `${board.name} at £${mountPrice} each` : `Please select a colour` }}
            br
            strong Undermount:
            |  {{ incUndermount ? `Included at £${calcUndermountPrice} each` : `Not included` }}
            br
            strong Quantity:
            |  {{ quantity }} at £{{ itemPrice }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BOverlay } from 'bootstrap-vue';
import { Mountboard, Size } from '@/types';

@Component({
    components: {
        BOverlay
    }
})
export default class MountOrderBreakdown extends Vue {
    @Prop(Boolean) isValid !: boolean;
    @Prop(Object) externalSize !: Size;
    @Prop(Object) apertureSize !: Size;
    @Prop(Boolean) incUndermount !: boolean;
    @Prop(Object) board !: Mountboard | null;
    @Prop(String) undermountPrice !: string;
    @Prop(String) mountPrice !: string;
    @Prop(Number) quantity !: number;

    get itemPrice(): string {
        return this.incUndermount ? this.undermountPrice : this.mountPrice;
    }

    get calcUndermountPrice(): string {
        return (parseFloat(this.undermountPrice.replace(/,/g, '')) - parseFloat(this.mountPrice.replace(/,/g, ''))).toFixed(2);
    }
}
</script>