var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BOverlay } from 'bootstrap-vue';
import axios from 'axios';
import round from 'lodash/round';
let DiscountsBreakdown = class DiscountsBreakdown extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.discounts = [];
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            // Get the frame discounts
            let { data } = yield axios
                .get('/api/pricing/discounts/discountPrices', {
                params: {
                    prefix: this.skuPrefix,
                    price: this.price.replace(/,/g, '')
                }
            });
            this.discounts = data.map(dat => {
                dat.discount = round(dat.discount * 100, 2);
                return dat;
            });
            this.loading = false;
        });
    }
    mounted() {
        this.refresh();
    }
};
__decorate([
    Prop(String)
], DiscountsBreakdown.prototype, "itemType", void 0);
__decorate([
    Prop(String)
], DiscountsBreakdown.prototype, "skuPrefix", void 0);
__decorate([
    Prop(String)
], DiscountsBreakdown.prototype, "price", void 0);
__decorate([
    Watch('price')
], DiscountsBreakdown.prototype, "refresh", null);
DiscountsBreakdown = __decorate([
    Component({
        components: {
            BOverlay
        }
    })
], DiscountsBreakdown);
export default DiscountsBreakdown;
