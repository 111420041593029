var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (buttons, containerClass, containerId, content, title) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"modal fade\""+pug.attr("id", containerId, true, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["modal-dialog",containerClass], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"modal-content\"\u003E";
if (title) {
pug_html = pug_html + "\u003Cdiv class=\"modal-header\"\u003E\u003Ch5 class=\"modal-title\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003Cbutton class=\"close\"\u003E&times;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"modal-body\"\u003E" + (null == (pug_interp = content) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
if (buttons && buttons.length > 0) {
pug_html = pug_html + "\u003Cdiv class=\"modal-footer\"\u003E";
// iterate buttons
;(function(){
  var $$obj = buttons;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var button = $$obj[key];
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(["btn",button.class], [false,true]), false, true)+pug.attr("data-key", key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = button.content) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var button = $$obj[key];
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(["btn",button.class], [false,true]), false, true)+pug.attr("data-key", key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = button.content) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "buttons" in locals_for_with ?
        locals_for_with.buttons :
        typeof buttons !== 'undefined' ? buttons : undefined, "containerClass" in locals_for_with ?
        locals_for_with.containerClass :
        typeof containerClass !== 'undefined' ? containerClass : undefined, "containerId" in locals_for_with ?
        locals_for_with.containerId :
        typeof containerId !== 'undefined' ? containerId : undefined, "content" in locals_for_with ?
        locals_for_with.content :
        typeof content !== 'undefined' ? content : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined));
    ;;return pug_html;}

module.exports = template