<template lang="pug">
    span.mountboard-option
        b-avatar.p-1(
            :class='classes'
            :style='{"background-color": `#${mountboard.dominant_colour}`}'
            :id="tooltipId"
            button
            @click='$emit("click")'
        )
            .colour &nbsp;
        b-tooltip(:target='tooltipId' triggers="hover") Larson Juhl {{ mountboard.name }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BAvatar, BTooltip } from 'bootstrap-vue';

import { Mountboard } from '@/types';

@Component({
    components: {
        BAvatar, BTooltip
    }
})
export default class MountboardOption extends Vue {
    @Prop(Object) mountboard !: Mountboard;
    @Prop(Boolean) selected !: boolean;

    get tooltipId(): string {
        return `${this.mountboard.stock_lookup_sku}-tt`;
    }

    get classes(): Record<string, boolean> {
        return { selected: this.selected }
    }
}
</script>