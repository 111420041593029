var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { BRow, BCol, BAvatar, BAvatarGroup, BOverlay, VBTooltip } from 'bootstrap-vue';
import ErrorPopup from './ErrorPopup.vue';
import Axios from 'axios';
let MountboardColours = class MountboardColours extends Vue {
    constructor() {
        super(...arguments);
        this.fetchedBoards = null;
        this.loading = false;
    }
    /**
     * If we're using lots of the same component, we might as well pass the
     * boards in by prop, otherwise we can just get them here
     */
    mounted() {
        if (this.mountboards == null) {
            this.loading = true;
            Axios.get('/api/materials/mountboard/forSale')
                .then(({ data }) => this.fetchedBoards = data)
                .catch(() => this.$refs.error.show())
                .finally(() => this.loading = false);
        }
    }
    get cbMountboards() {
        let boards;
        if (this.mountboards) {
            boards = this.mountboards;
        }
        else if (this.fetchedBoards) {
            boards = this.fetchedBoards;
        }
        else {
            return null;
        }
        // If a board is not selected, default to the first featured
        if (!this.selectedMountboard) {
            this.$nextTick(() => this.$emit('input', boards.featured[0]));
        }
        return boards;
    }
    get featuredSize() {
        if (this.small) {
            return '2.8rem';
        }
        else if (this.xl) {
            return '9rem';
        }
        else {
            return '6rem';
        }
    }
    get size() {
        if (this.small) {
            return '2.25rem';
        }
        else if (this.xl) {
            return '6rem';
        }
        else {
            return 'lg';
        }
    }
};
__decorate([
    ModelSync('mountboard', 'input', { type: Object })
], MountboardColours.prototype, "selectedMountboard", void 0);
__decorate([
    Prop(Object)
], MountboardColours.prototype, "mountboards", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], MountboardColours.prototype, "small", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], MountboardColours.prototype, "xl", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], MountboardColours.prototype, "horizontal", void 0);
MountboardColours = __decorate([
    Component({
        components: { BRow, BCol, BAvatar, BAvatarGroup, BOverlay, ErrorPopup },
        directives: { 'b-tooltip': VBTooltip }
    })
], MountboardColours);
export default MountboardColours;
