var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormInput, BInputGroup, BFormSelect, BInputGroupText } from 'bootstrap-vue';
let DimensionInput = class DimensionInput extends Vue {
    get nearestFrac() {
        const decimal = (this.rawDimension / 25.4) % 1;
        const frac = Math.round(decimal * 8) / 8;
        const fractions = {
            0.000: '',
            0.125: '⅛',
            0.250: '¼',
            0.375: '⅜',
            0.500: '½',
            0.625: '⅝',
            0.750: '¾',
            0.875: '⅞',
        };
        return fractions[frac];
    }
    get rawDimension() {
        return this.value;
    }
    set rawDimension(dimension) {
        this.$emit('input', Math.ceil(dimension));
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], DimensionInput.prototype, "value", void 0);
__decorate([
    Prop({ type: String, default: 'mm' })
], DimensionInput.prototype, "unit", void 0);
__decorate([
    Prop({ type: String, default: 'md' })
], DimensionInput.prototype, "size", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DimensionInput.prototype, "readonly", void 0);
__decorate([
    Prop({ type: Boolean, default: null })
], DimensionInput.prototype, "valid", void 0);
DimensionInput = __decorate([
    Component({
        components: { BFormInput, BInputGroup, BFormSelect, BInputGroupText }
    })
], DimensionInput);
export default DimensionInput;
