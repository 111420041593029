var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import Dialog from './dialog';
const addToCart = (item, reload = true) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield axios.post('/api/cart/add', item);
        const continueBtn = {
            class: 'btn-secondary',
            content: 'Continue Shopping'
        };
        if (reload) {
            continueBtn.onClick = () => window.location.reload();
        }
        new Dialog('add-to-cart-success', {
            content: 'Item successfully added to cart',
            buttons: [
                continueBtn,
                {
                    class: 'btn-brand',
                    content: 'Checkout',
                    onClick: () => window.location.href = '/cart'
                }
            ]
        }).open();
        const event = new Event('cart-update');
        const $body = document.querySelector('body');
        if ($body) {
            $body.dispatchEvent(event);
        }
    }
    catch (e) {
        const err = e;
        if (err.response) {
            new Dialog('add-to-cart-error', err.response.data.error).open();
        }
        else {
            new Dialog('add-to-cart-error', "Sorry, we ran into a problem. Please try e-mailing us your order and we will try to track down the fault in our website").open();
        }
    }
});
/**
 * Find addresses for a given postcode
 * @param postcode Postcode to find addresses for
 * @returns Promise<Address[]>
 */
const postcodeLookup = function (postcode) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield axios.get(`https://api.getAddress.io/find/${postcode.replace(/ /g, '')}`, {
                params: {
                    "api-key": "y9GGF7MqVkSbJOIAHl9Nkw4428"
                }
            });
            if (data.addresses.length === 0) {
                throw new Error(`Could not find any addresses that matched the postcode '${postcode}'`);
            }
            return sortBy(data.addresses
                .map(address => address.split(', '))
                .map(address => ({
                line1: address[0],
                line2: address[1],
                city: address[5],
                postcode
            })), addr => Number(addr.line1.split(' ')[0]));
        }
        catch (e) {
            throw new Error(`We were unable to find the postcode you have entered.`);
        }
    });
};
/**
 * Get the current cart
 * @returns Promise<Cart>
 */
const getCart = function () {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.get('/api/cart/contents');
        return data;
    });
};
/**
 * Empty the current cart of items
 * @returns Promise<boolean>
 */
const emptyCart = function () {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/empty');
        return data.success;
    });
};
/**
 * Update the quantity of an item
 * @param itemId ID of the item
 * @param quantity New quantity
 * @returns Promise<boolean>
 */
const updateQuantity = function (itemId, quantity) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/update', { id: itemId, quantity });
        return data.success;
    });
};
/**
 * Delete a specific item from the cart
 * @param itemId ID of the item
 * @returns Promise<boolean>
 */
const deleteItem = function (itemId) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/delete', { id: itemId });
        return data.success;
    });
};
/**
 * Set the delivery postcode of a cart
 * @param postcode Postcode to send to
 * @returns Promise<boolean>
 */
const setPostcode = function (postcode) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/shippingPostcode', { postcode });
        return data.success;
    });
};
const setShippingZone = function (zone) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/shippingZone', { zoneName: zone });
        return data.success;
    });
};
/**
 * Set the collection of an item
 * @returns Promise<boolean>
 */
const setCollection = function () {
    return __awaiter(this, void 0, void 0, function* () {
        return setShippingZone('Collection');
    });
};
/**
 * Set the VAT mode for the cart
 * @param mode VAT mode
 * @returns Promise<boolean>
 */
const setVatMode = function (mode) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/vatSwitch', { mode });
        return data.success;
    });
};
/**
 * Send a cart enquiry to sales
 * @param enquiry Cart enquiry details (of the customer)
 * @returns Promise<boolean>
 */
const sendEnquiry = function (enquiry) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/enquiry', {
            name: enquiry.name,
            email: enquiry.email,
            message: enquiry.message
        });
        return data.success;
    });
};
/**
 * Process the order in Vallaton Apps
 * @returns Promise<ProcessOrderResponse>
 */
const processOrder = function () {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/processOrder');
        return data;
    });
};
/**
 * Check the validity of an email address
 * @param email Email to check
 * @returns boolean
 */
const isEmailValid = function (email) {
    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEX.test(email);
};
/**
 * Store the customer information for the cart
 * @param cartId ID of the cart
 * @param customer Customer information
 * @param isCollection
 * @returns Promise<number>
 */
const storeCustomer = function (cartId, customer, isCollection) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/storeCustomer', {
            cartID: cartId,
            firstName: customer.forename,
            surname: customer.surname,
            email: customer.email,
            phoneNumber: customer.phone,
            billing: customer.billingAddress,
            delivery: !isCollection ? customer.deliveryAddress : null
        });
        return data.customerCartID;
    });
};
/**
 * Initiate the payment process in the database
 * @param customerCartId ID of the customer cart record
 * @returns Promise<boolean> Whether card payments are enabled
 */
const initiatePayment = function (customerCartId, note) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post('/api/cart/initiatePayment', {
            customerCartID: customerCartId,
            note
        });
        return data.isCardEnabled;
    });
};
/**
 * Mark a customer cart record as paid
 * @param customerCartId ID of the customer cart record
 * @param transactionId ID of the payment reference
 * @param paymentType Type of payment (card or paypal)
 */
const orderPaid = function (customerCartId, transactionId, paymentType, note) {
    return axios.post('/api/cart/paid', {
        customerCartID: customerCartId,
        paymentRef: transactionId,
        paymentType,
        note
    });
};
const applyDiscountCode = function (discountCode) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.post("/api/cart/applyDiscountCode", { discountCode });
        return data.success;
    });
};
export { addToCart, postcodeLookup, getCart, emptyCart, updateQuantity, deleteItem, setPostcode, setShippingZone, setCollection, setVatMode, sendEnquiry, processOrder, isEmailValid, storeCustomer, initiatePayment, orderPaid, applyDiscountCode };
