var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
/**
 * Get the current VAT mode
 * @returns Currently selected VAT mode (ex or inc)
 */
export function getVatMode() {
    const vatMode = document.querySelector('body');
    return (vatMode === null || vatMode === void 0 ? void 0 : vatMode.getAttribute('data-vat-mode')) == 'inc VAT' ? 'inc' : 'ex';
}
/**
 * Check if the user is an administrator
 * @returns Whether the current user is logged in (admin)
 */
export function isAdmin() {
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = yield axios.get('/api/user/isLoggedIn');
        return data.isLoggedIn;
    });
}
