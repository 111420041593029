<template lang="pug">
    b-overlay(:show='loading')   
        b-avatar-group(
            overlap=0
            rounded='lg'
            :size='!small ? `6rem` : `2.8rem`'
            variant='light'
            v-if='mountboards.featured'
        )
            mountboard-option(
                v-for='board in mountboards.featured' :key='board.id'
                :mountboard='board'
                :selected='selectedMountboard && selectedMountboard.stock_lookup_sku == board.stock_lookup_sku'
                @click='onMountboardClick(board)'
            )
        b-avatar-group(
            overlap=0
            rounded='lg'
            :size='!small ? `lg` : `2.25rem`'
            variant='light'
            v-if='mountboards.standard'
        )
            mountboard-option(
                v-for='board in mountboards.standard' :key='board.id'
                :mountboard='board'
                :selected='selectedMountboard && selectedMountboard.stock_lookup_sku == board.stock_lookup_sku'
                @click='onMountboardClick(board)'
            )
        hr(v-if='!small')
        p(v-if='selectedMountboard && !small') #[strong {{ selectedMountboard.name }}:] {{ selectedMountboard.description }}
</template>

<script lang="ts">
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { BOverlay, BAvatarGroup } from 'bootstrap-vue';

import MountboardOption from '@/components/MountboardOption/MountboardOption.vue';
import { Mountboard, MountboardGroup } from '@/types';

@Component({
    components: {
        BOverlay, BAvatarGroup,
        MountboardOption
    }
})
export default class MountboardSelector extends Vue {
    @ModelSync('mountboard', 'input', { type: Object })
    readonly selectedMountboard !: Mountboard;

    @Prop({ type: Boolean, default: false }) small !: boolean;
    @Prop({ type: Boolean, default: false }) loading !: boolean;

    @Prop({ type: Object, required: true }) mountboards !: MountboardGroup;

    onMountboardClick(board: Mountboard) {
        this.$emit('input', board);
    }
}
</script>

<style lang="postcss" scoped>
/deep/.b-avatar {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;

    &.selected {
        box-shadow: 0 0 0.2rem 0 var(--brandColourLighter);
        border-color: var(--brandColourLighter);
    }
}

/deep/.colour-p {
    width: 100%;
    height: 100%;
    display: block;
}

.b-overlay-wrap {
    min-height: 6rem;
}
</style>