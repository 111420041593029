<template lang="pug">
    b-row
        b-col.mb-2.mb-sm-0(sm)
            dimension-input(
                :unit='unit'
                :size='size'
                :readonly='readonly'
                v-model='selectedSize.width'
                :valid='isWidthValid'
            )
        b-col.text-center.mb-2.mb-sm-0(sm=1)
            p.mb-0.pt-1(v-if='showX') x
        b-col(sm)
            dimension-input(
                :unit='unit'
                :size='size'
                :readonly='readonly'
                v-model='selectedSize.length'
                :valid='isLengthValid'
            )
</template>

<script lang="ts">
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { BRow, BCol } from 'bootstrap-vue';

import DimensionInput from '@/components/DimensionInput/DimensionInput.vue';
import { Size, Unit, ElementSize } from '@/types';

@Component({
    components: {
        BRow, BCol,
        DimensionInput
    }
})
export default class SizeInput extends Vue {
    @ModelSync('setSize', 'input', { type: Object })
    readonly selectedSize !: Size;

    @Prop({ type: String, default: 'mm' }) unit !: Unit;
    @Prop({ type: String, default: 'mm' }) size !: ElementSize;
    @Prop({ type: Boolean, default: false }) readonly !: boolean;
    @Prop({ type: Boolean, default: false }) valid !: boolean;
    @Prop({ type: Boolean, default: true }) showX !: boolean;

    get isWidthValid(): boolean | null {
        if (this.valid !== null) return this.valid;

        if (this.selectedSize.width < 0) return false;

        return null;
    }

    get isLengthValid(): boolean | null {
        if (this.valid !== null) return this.valid;

        if (this.selectedSize.length < 0) return false;

        return null;
    }
}
</script>