var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: _vm.horizontal ? 8 : 12 } },
            [
              _vm.cbMountboards && _vm.cbMountboards.featured
                ? _c(
                    "b-avatar-group",
                    {
                      attrs: {
                        overlap: "0",
                        rounded: "lg",
                        size: _vm.featuredSize,
                        variant: "light",
                      },
                    },
                    _vm._l(_vm.cbMountboards.featured, function (board) {
                      return _c(
                        "b-avatar",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          key: board.id,
                          staticClass: "p-1",
                          class: {
                            selected:
                              _vm.selectedMountboard &&
                              _vm.selectedMountboard.stock_lookup_sku ==
                                board.stock_lookup_sku,
                          },
                          style: {
                            "background-color": `#${board.dominant_colour}`,
                          },
                          attrs: {
                            title: `Larson Juhl ${board.name}`,
                            button: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(`input`, board)
                            },
                          },
                        },
                        [_c("div", { staticClass: "colour" }, [_vm._v(" ")])]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.cbMountboards && _vm.cbMountboards.standard
                ? _c(
                    "b-avatar-group",
                    {
                      attrs: {
                        overlap: "0",
                        rounded: "lg",
                        size: _vm.size,
                        variant: "light",
                      },
                    },
                    _vm._l(_vm.cbMountboards.standard, function (board) {
                      return _c(
                        "b-avatar",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          key: board.id,
                          staticClass: "p-1",
                          class: {
                            selected:
                              _vm.selectedMountboard &&
                              _vm.selectedMountboard.stock_lookup_sku ==
                                board.stock_lookup_sku,
                          },
                          style: {
                            "background-color": `#${board.dominant_colour}`,
                          },
                          attrs: {
                            title: `Larson Juhl ${board.name}`,
                            button: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(`input`, board)
                            },
                          },
                        },
                        [_c("div", { staticClass: "colour" }, [_vm._v(" ")])]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              !_vm.small && !_vm.horizontal ? _c("hr") : _vm._e(),
            ],
            1
          ),
          _c("b-col", { attrs: { sm: _vm.horizontal ? 4 : 12 } }, [
            _vm.selectedMountboard && !_vm.small && !_vm.xl
              ? _c("div", [
                  _c("p", [
                    _c("strong", [_vm._v(_vm._s(_vm.selectedMountboard.name))]),
                    _vm._v(": " + _vm._s(_vm.selectedMountboard.description)),
                  ]),
                ])
              : _vm._e(),
            _vm.selectedMountboard && _vm.xl
              ? _c("div", [
                  _c("h3", { staticClass: "mt-0" }, [
                    _vm._v(_vm._s(_vm.selectedMountboard.name)),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.selectedMountboard.description))]),
                ])
              : _vm._e(),
          ]),
          _c("error-popup", { ref: "error" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }