/* eslint-disable @typescript-eslint/no-var-requires */
const template = require('./dialog.pug');
export default class Dialog {
    constructor(id, options) {
        this.modalId = `modal-${id}`;
        this.modal = null;
        this.modalFragment = document.createRange().createContextualFragment('');
        const defaultOptions = {
            containerClass: '',
            containerId: this.modalId,
            title: undefined,
            content: undefined,
            buttons: [
                {
                    content: 'OK',
                    class: 'btn-primary',
                    onClick: this.close
                }
            ]
        };
        if (typeof options === 'string') {
            this.options = Object.assign(defaultOptions, { content: options });
        }
        else {
            this.options = Object.assign(defaultOptions, options);
        }
        this.update(this.options);
    }
    update(options) {
        this.options = options || this.options;
        this.modalFragment = document
            .createRange()
            .createContextualFragment(template(this.options));
    }
    open() {
        var _a, _b, _c, _d;
        if (!this.modalFragment) {
            throw new Error("Attempting open of modal with no content");
        }
        document.body.appendChild(this.modalFragment);
        this.modal = document.getElementById(this.modalId);
        if (!this.modal) {
            throw new Error("Unable to create modal.");
        }
        if (this.options.title) {
            (_b = (_a = this.modal) === null || _a === void 0 ? void 0 : _a.querySelector('.close')) === null || _b === void 0 ? void 0 : _b.addEventListener("click", this.close);
        }
        if (this.options.buttons && this.options.buttons.length > 0) {
            (_d = (_c = this.modal) === null || _c === void 0 ? void 0 : _c.querySelector('.modal-footer')) === null || _d === void 0 ? void 0 : _d.childNodes.forEach((node, key) => {
                const func = this.options.buttons[key].onClick;
                node.addEventListener('click', () => func ? func() : this.close());
            });
        }
        document.body.appendChild(this.modal);
        // Add the show class after a bit so that the transition works
        setTimeout(() => {
            var _a;
            (_a = this.modal) === null || _a === void 0 ? void 0 : _a.classList.add('show');
        }, 100);
    }
    close() {
        var _a, _b;
        (_a = this.modal) === null || _a === void 0 ? void 0 : _a.classList.remove('show');
        (_b = this.modal) === null || _b === void 0 ? void 0 : _b.addEventListener('transitionend', () => {
            var _a;
            (_a = document.getElementById(this.modalId)) === null || _a === void 0 ? void 0 : _a.remove();
            this.update();
        });
        if (this.options.onClosed instanceof Function) {
            this.options.onClosed();
        }
    }
}
