<template lang="pug">
    b-form-radio-group(
        v-model='selectedUnit'
        :options='units'
        :size='size'
        buttons
        button-variant='brand'
    )
</template>

<script lang="ts">
import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
import { BFormRadioGroup } from 'bootstrap-vue';

import { BvOptionField, ElementSize, Unit } from '@/types';

@Component({
    components: {
        BFormRadioGroup
    }
})
export default class UnitSelector extends Vue {
    @ModelSync('unit', 'input', { type: String })
    readonly selectedUnit !: Unit;

    @Prop({ type: String, default: 'md' }) size !: ElementSize;

    units: BvOptionField[] = [
        { text: 'mm', value: 'mm' },
        { text: 'cm', value: 'cm' },
        { text: 'inch', value: 'inch' }
    ];
}
</script>