var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-input-group",
    {
      attrs: { size: _vm.size },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _vm.unit == `inch` && _vm.nearestFrac
                ? _c("b-input-group-text", [
                    _c("small", [
                      _c("em", [
                        _vm._v(
                          "(~ " +
                            _vm._s(
                              _vm.rawDimension / 25.4 -
                                ((_vm.rawDimension / 25.4) % 1)
                            ) +
                            " " +
                            _vm._s(_vm.nearestFrac) +
                            '")'
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("b-input-group-text", [_vm._v(_vm._s(_vm.unit))]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.unit == `mm`
        ? _c("b-form-input", {
            attrs: {
              type: _vm.readonly ? `text` : `number`,
              number: "",
              min: "0",
              step: "1",
              disabled: _vm.readonly,
              value: _vm.rawDimension,
              state: _vm.valid,
            },
            on: {
              input: function ($event) {
                _vm.rawDimension = $event
              },
            },
          })
        : _vm._e(),
      _vm.unit == `cm`
        ? _c("b-form-input", {
            attrs: {
              type: _vm.readonly ? `text` : `number`,
              number: "",
              min: "0",
              step: "0.1",
              disabled: _vm.readonly,
              value: _vm.rawDimension / 10,
              state: _vm.valid,
            },
            on: {
              input: function ($event) {
                _vm.rawDimension = $event * 10
              },
            },
          })
        : _vm._e(),
      _vm.unit == `inch`
        ? _c("b-form-input", {
            attrs: {
              type: _vm.readonly ? `text` : `number`,
              number: "",
              min: "0",
              step: "0.1",
              disabled: _vm.readonly,
              value: Math.round((_vm.rawDimension / 25.4) * 10) / 10,
              state: _vm.valid,
            },
            on: {
              input: function ($event) {
                _vm.rawDimension = $event * 25.4
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }