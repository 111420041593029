var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
    _vm._v("This product has been discontinued. "),
    _vm.replacement
      ? _c("span", [
          _vm._v("Please view our replacement product "),
          _c("a", { attrs: { href: _vm.url } }, [
            _vm._v(_vm._s(_vm.replacement.name)),
          ]),
          _vm._v("."),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }