<template lang="pug">
    b-form-select(
        v-model='selectedSize'
        :options='options'
        :size='elSize'
    )
</template>

<script lang="ts">
import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
import { BFormSelect } from 'bootstrap-vue';
import groupBy from 'lodash/groupBy';

import { BvGroupedOptionField, BvOptionField, ElementSize, StandardSize } from '@/types';

@Component({
    components: {
        BFormSelect
    }
})
export default class StandardSizeSelector extends Vue {
    @ModelSync('size', 'change', { type: Object })
    readonly selectedSize !: StandardSize;

    @Prop({ type: String, default: 'md' }) elSize !: ElementSize;

    @Prop(Array) sizes !: StandardSize[];

    get options(): BvGroupedOptionField[] {
        return [
            { label: 'A Sizes', options: this.standardSizes.a },
            { label: 'CM Sizes', options: this.standardSizes.cm },
            { label: 'Inch Sizes', options: this.standardSizes.inch },
            { label: 'Square Sizes', options: this.standardSizes.square }
        ]
    }

    get standardSizes(): Record<string, BvOptionField[]> {
        return groupBy(
            this.sizes.map(size => ({
                value: size,
                text: size.name,
                type: size.type
            })), 
            'type'
        )
    }
}
</script>