var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-form-radio-group", {
    attrs: {
      options: _vm.units,
      size: _vm.size,
      buttons: "",
      "button-variant": "brand",
    },
    model: {
      value: _vm.selectedUnit,
      callback: function ($$v) {
        _vm.selectedUnit = $$v
      },
      expression: "selectedUnit",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }